var stayFromBottom = 0;
var pathname;
var isPortrait = false;
jQuery(function(){
	pathname = jQuery(location).attr('href');
	jQuery('#social-sharing ul li a').each(function() {
		var href= jQuery(this).attr('href');
		jQuery(this).attr('href',href+pathname);
	});
	if (window.innerHeight > window.innerWidth) {
			isPortrait = true;
			jQuery('.table-overlay').remove();
			jQuery('.table-arrow').remove();
			jQuery('.click').addClass('formerClick').removeClass('click');
			jQuery('table').each(function(){jQuery(this).attr('style','width:100%;');})
	}

	jQuery(window).on('orientationchange', function(event) {
		if (window.innerHeight > window.innerWidth) {
			isPortrait = true;
			jQuery('.table-overlay').remove();
			jQuery('.table-arrow').remove();
			jQuery('.click').addClass('formerClick').removeClass('click');
			jQuery('.table-wrapper table').each(function(){jQuery(this).attr('style','width:98%;');})
		} else {
			isPortrait = false;
			jQuery('.formerClick').removeClass('formerClick').addClass('click');
			jQuery('.table-wrapper table').each(function() {
				if(jQuery(this).hasClass('extend'))
				{
					var divOverlay= '<div class="table-overlay"> </div>';
					var divArrow='<div class="table-arrow"> </div>';
					//prepend deze elementen voor de table
					jQuery(this).parent().prepend(divOverlay,divArrow);
				}
			});
		}
		//reset alle uitgeklapte tabellen
		jQuery('.table-wrapper').each(function() {
			jQuery(this).children('div').show();
			jQuery(this).css({'position':'relative','left':'0', 'width': '98%', 'box-shadow': '', 'border-color': ''});
			if(jQuery(this).children('table').hasClass('extend') && !isPortrait) {
				jQuery(this).children('table').css({'width': '150%'});
			} else {
				jQuery(this).children('table').css({'width': '100%'});
			}
			var height = jQuery(this).parent('div').height();
			jQuery(this).parent('div').css({'height':height+'px'});
			
		});
		
    });
	jQuery('.table-wrapper').each(function() {
		var height = jQuery(this).parent('div').height();
		jQuery(this).parent('div').css({'height':height+'px'});
	});
	jQuery('.table-overlay table').each(function() {
		if(jQuery(this).width() > jQuery(this).parent().width() && !isPortrait)
		{
			var divOverlay= '<div class="table-overlay"> </div>';
			var divArrow='<div class="table-arrow"> </div>';
			//prepend deze elementen voor de table
			jQuery(this).parent().prepend(divOverlay,divArrow);
		}
		if(!jQuery(this).hasClass('extend')) {
			jQuery(this).addClass('extend');
		}
	});
	jQuery('.table-zoom').click(function() {
		jQuery(this).closest('.holder').addClass('popup');
		if(!isPortrait) {
			var tableWidth = jQuery(this).closest('.table-wrapper').closest('.contentrow').width();//jQuery(this).closest('.table-wrapper').children('table').width();
			var section = jQuery(this).closest('.section').width();
			var outdentLeft = (section - jQuery(this).closest('.table-wrapper').closest('.contentrow').width() +12) ;//(jQuery(this).width() - tableWidth) / 2;
			
			jQuery(this).closest('.table-wrapper').children('div').hide();
			jQuery(this).closest('.table-wrapper').parent('div').css({'position':'inherit'});
			jQuery(this).closest('.table-wrapper').css({'left': outdentLeft+'px','border-color': '#CCC', 'width':parseInt(tableWidth)+'px','position':'absolute','z-index':'1'});
			jQuery(this).closest('.table-wrapper').children('table').css({'width':'98%'});
		}
	});
	jQuery('.table-close').click(function(){
	  jQuery(this).closest('.holder').removeClass('popup');
		if(!isPortrait) {
			jQuery(this).closest('.table-wrapper').parent('div').css({'position':'inherit'});
			jQuery(this).closest('.table-wrapper').children('div').show();
			jQuery(this).closest('.table-wrapper').css({'position':'relative'});
			jQuery(this).closest('.table-wrapper').children('table').css({'width': '150%'});
			jQuery(this).closest('.table-wrapper').css({'width': '98%', 'left': '0px', 'border-color': ''});
		}
	});
	
	//maak arrows aan en verander thead naar table
	jQuery('.moveablerows table thead').each(function() {
		if(jQuery(this).width() > jQuery(this).closest('.moveablerows').width())
		{
			jQuery(this).css({'width': jQuery(this).width()+'px','height':jQuery(this).height()+'px'});
			jQuery(this).find('th, td').each(function() { 
				jQuery(this).css({'width': jQuery(this).width()+'px','height':jQuery(this).height()+'px'});
			});
			var table = jQuery(this).parent('table');
			var tableClass = table.attr('class');
			var tbody = '<tbody style="position:relative;width:100%;height:100%;"><td class="table-arrow-left hideArrow">&nbsp;</td><td class="midempty">&nbsp;</td><td class="table-arrow-right">&nbsp;</td></tbody/>';
			
			var moveablerows = jQuery(this).closest('.moveablerows');
			moveablerows.append('<div class="showhead"><div style="position:relative; width:100%; height:100%;"><table class="'+tableClass+'" cellpadding="0" cellspacing="0"  height="100%" style="overflow:hidden; table-layout:fixed; width:'+jQuery(this).parent().width()+'px;"><thead>'+jQuery(this).html()+'</thead>'+tbody+'</table></div></div>');
			table.css('table-layout','fixed');
			
			//set div height so the arrows are 'centered'
			var viewportHeight = jQuery(window).height();
			var baseDivHeight;
			if(viewportHeight > moveablerows.height()) {
				baseDivHeight =  moveablerows.height();
			} else {
				baseDivHeight =  viewportHeight;
			}
			moveablerows.children('.showhead').css({'width':moveablerows.width()+'px', 'height': baseDivHeight+'px'});
		} 
	});
	jQuery('.click table thead').each(function() {
		if(jQuery(this).width() > jQuery(this).closest('.click').width())
		{
			jQuery(this).css({'width': jQuery(this).width()+'px','height':jQuery(this).height()+'px'});
			jQuery(this).find('th, td').each(function() { 
				jQuery(this).css({'width': jQuery(this).width()+'px','height':jQuery(this).height()+'px'});
			});
			var table = jQuery(this).parent('table');
			var tableClass = table.attr('class');			
			var click = jQuery(this).closest('.click');
			click.append('<div class="showhead"><div style="position:relative; width:100%; height:100%;"><table class="'+tableClass+'" cellpadding="0" cellspacing="0" style="overflow:hidden; table-layout:fixed; width:'+jQuery(this).parent().width()+'px;"><thead>'+jQuery(this).html()+'</thead></table></div></div>');
			table.css('table-layout','fixed');
			
			//set div height so the arrows are 'centered'
			var viewportHeight = jQuery(window).height();
			var baseDivHeight;
			if(viewportHeight > click.height()) {
				baseDivHeight =  click.height();
			} else {
				baseDivHeight =  viewportHeight;
			}
			click.children('.showhead').css({'width':click.width()+'px', 'height': baseDivHeight+'px'});
		} 
	});
	jQuery('.click').click(function() {
		jQuery(this).toggleClass('clicked');
		if(jQuery(this).hasClass('clicked')) {
			//vergroot
			var tableHeight = jQuery(this).children('table').height();
			var tableWidth = jQuery(this).children('table').width();
			var section = jQuery(this).closest('.section').width();

			
			jQuery(this).parent().css({'position':'inherit','height':tableHeight+'px','width':tableWidth});
			var outdentLeft = (section - jQuery(this).closest('.contentrow').width() +8) ;//(jQuery(this).width() - tableWidth) / 2;
			jQuery(this).children('div.table-overlay,div.table-arrow').hide();
			jQuery(this).children('table').css('width','100%');
			jQuery(this).css({'left': outdentLeft+'px','box-shadow':'5px 5px 5px #CCC','border-color': '#CCC', 'width':parseInt(tableWidth)+'px','position':'absolute'});
			if (jQuery(this).children('div.showhead').hasClass('stick')) {
				//position fixed
				jQuery(this).children('div.showhead').css({'width':'100%', 'left': jQuery(this).offset().left+'px'});
			} else {
				//position absolute
				jQuery(this).children('div.showhead').css({'width':'100%', 'left':'0px'});
			}
		} else {
			//return to normal
			var divWidth = jQuery(this).css('width');
			jQuery(this).children('div.table-overlay,div.table-arrow').show();
			jQuery(this).children('table').css('width',divWidth);
			jQuery(this).css({'width': '100%', 'left': '0px', 'box-shadow': '', 'border-color': '','position':'relative'});
            jQuery(this).children('div.showhead').css({'width':jQuery(this).css('width'), 'left': 'auto'});
			if(jQuery(this).find('.stick')) {
				jQuery(this).find('.stick').removeClass("stick").css({'left':'0px'});
			}
		}
	});

	jQuery(window).scroll(function() {
		jQuery('.showhead').each(function() { 
		 if(jQuery(this).closest('.click').hasClass('clicked')){
			var s = jQuery(this);
			var pos = s.position();	
			var elementFromTop = s.parent().offset().top;
			var stickermax = (s.parent().offset().top+s.parent().height());
			var windowpos = jQuery(window).scrollTop();
			var viewportHeight = jQuery(window).height();
			var left = s.parent().offset().left;
			if (windowpos >= elementFromTop && windowpos < (stickermax-stayFromBottom)) { // tussen top en bottom van div dan meescrollen
				var marginLeft = s.css("margin-left");
				var width = s.parent().width();

				var divHeight;
				//als viewport groter is dan wat je nog ziet van tabel dan verklein grote van div
				if ((stickermax-stayFromBottom) < (viewportHeight + windowpos)   ) {
					divHeight = viewportHeight - ( (windowpos  + viewportHeight) - stickermax);
				} else {
					divHeight = viewportHeight;
				}
				
				s.attr("style", "margin-left: "+marginLeft+"; height: "+divHeight+"px; width:"+width+"px; left:"+left+'px;'); //kill absolute positioning
				s.addClass("stick"); //stick it
			} else if (windowpos >= (stickermax - stayFromBottom)) {  // onder parent di
				s.removeClass("stick"); //un-stick
				s.css({'position': "absolute", 'top': (stickermax-800) + "px",'left':'0px'}); //set sticker right above the footer
			} else { //erboven, buiten beeld
				s.removeClass("stick"); //top of page
				s.css({'left':'0px'}); //set sticker right above the footer
			}
		 }
		});
	});
	jQuery('.clickIMG').on('click',function(){ 
		if(jQuery(this).hasClass('expanded')) {
			jQuery(this).children('img').css({'position':'relative','width': '100%','left':'0'});
			var height = jQuery(this).children('img').height();
			jQuery(this).css({'position':'inherit','height': parseInt(height)+'px'});
		} else {
			
			var wrapperHeight = jQuery(this).closest('.contentrow').width();
			var left = jQuery('#sub').outerWidth(true);
			jQuery(this).children('img').css({'position':'absolute','width': wrapperHeight+'px','left':'-'+left+'px','z-index':'1'});
			var height = jQuery(this).children('img').height();
			jQuery(this).css({'position':'inherit','height': parseInt(height)+'px'});
		}
		jQuery(this).toggleClass('expanded');
	});
	jQuery('.table-arrow-right').on('click',function(){
		
		var moveablerows = jQuery(this).closest('.moveablerows');
		
		var table = jQuery(moveablerows).children('table');
		var tableWidth = table.width();
		var maxNegativeLeft = (jQuery(moveablerows).width() - jQuery(table).width());
		jQuery(moveablerows).find('table').each(function(){
			jQuery(this).animate({"margin-left": maxNegativeLeft+'px'}, "slow");
			jQuery(this).find('.table-arrow-left').removeClass("hideArrow");
			jQuery(this).css('width',tableWidth+'px');
			
		});
		jQuery(moveablerows).children('div.table-overlay-left').removeClass('hidden');
		jQuery(moveablerows).children('div.table-overlay-right').addClass('hidden');
		jQuery(this).addClass("hideArrow");
	});
	
	jQuery('.table-arrow-left').on('click',function(){
		var moveablerows = jQuery(this).closest('.moveablerows');
		jQuery(moveablerows).find('table').each(function(){
			jQuery(this).animate({"margin-left": '0px'}, "slow");
			jQuery(this).find('.table-arrow-right').removeClass("hideArrow");
			
		});
		jQuery(moveablerows).children('div.table-overlay-left').addClass('hidden');
		jQuery(moveablerows).children('div.table-overlay-right').removeClass('hidden');
		jQuery(this).addClass("hideArrow");

	});
});